import React from 'react'
import PropTypes from 'prop-types'
import { useRecoilValue } from 'recoil'
import { browserState } from '@/store/browserState'
import { ProjectHeaderDesktop } from '@/components/ProjectHeaderDesktop'
import { ProjectHeaderMobile } from '@/components/ProjectHeaderMobile'
import * as styles from './Article.styles.scss'

const Article = ({
  title,
  children,
  details,
  headerMedia,
  intro,
  description,
}) => {
  const { device } = useRecoilValue(browserState)

  const headerProps = {
    title,
    details,
    media: headerMedia,
    intro,
    description,
  }

  return (
    <div className={styles.Project}>
      {device === 'desktop' ? (
        <ProjectHeaderDesktop {...headerProps} />
      ) : (
        <ProjectHeaderMobile {...headerProps} />
      )}
      {children && <div>{children}</div>}
    </div>
  )
}

Article.defaultProps = {
  details: [],
}

Article.propTypes = {
  title: PropTypes.node,
  intro: PropTypes.node,
  description: PropTypes.node,
  children: PropTypes.node,
  details: PropTypes.arrayOf(PropTypes.object),
  headerMedia: PropTypes.object,
}

export default Article
