import React from 'react'
import { graphql } from 'gatsby'
import { useStoryblok } from '@/storyblok/hooks/useStoryblok'
import Article from '@/storyblok/templates/Article.storyblok'

/**
 * Storyblok Article blueprint
 */

const ArticleBlueprint = ({ data, location, pageContext }) => {
  const story = useStoryblok(data.story, location)

  return (
    <Article
      story={story}
      pageContext={pageContext}
      articles={data.articles?.edges}
    />
  )
}

export default ArticleBlueprint

export const query = graphql`
  query StoryblokArticleByID($id: String!) {
    story: storyblokEntry(id: { eq: $id }) {
      id
      name
      content
      slug
      uuid
      full_slug
      field_component
      first_published_at
    }
    articles: allStoryblokEntry(
      filter: { field_component: { eq: "article" } }
      sort: { order: DESC, fields: first_published_at }
    ) {
      edges {
        node {
          name
          slug
          full_slug
          content
          uuid
          first_published_at
        }
      }
    }
  }
`
