import React from 'react'
import PropTypes from 'prop-types'
import SbEditable from 'storyblok-react'
import { getRichText } from '@/storyblok/helpers/getRichText'
import { getArticle } from '@/storyblok/helpers/getArticle'
import { getMedia } from '@/storyblok/helpers/getMedia'
import { getDate } from '@/storyblok/helpers/getDate'
import { useArticleTags } from '@/storyblok/hooks/useArticleTags'
import { useBloks } from '@/storyblok/hooks/useBloks'
import { BlockRenderer } from '@/storyblok/components/BlockRenderer'
import { RelatedThumbs } from '@/components/RelatedThumbs'
import Article from '@/templates/Article'
import { blockTypes } from '@/constants/blockTypes'

const ArticleStoryblok = ({ story, articles }) => {
  const tags = useArticleTags()
  const blocks = useBloks(story.content.body || [])

  const details = []
  story.content.details.forEach((item) => {
    details.push({
      uid: item._uid,
      heading: item.heading,
      description: getRichText(item.description),
    })
  })

  if (!story.content.hide_publish_date) {
    details.push({
      uid: 'publish_date',
      heading: 'Published',
      description: getDate(story.first_published_at),
    })
  }

  const relatedItems = articles
    .filter((i) => i.node.uuid !== story.uuid) // Remove current article
    .slice(0, 4) // Limit to 4
    .map(({ node }) => getArticle(node, { allTags: tags })) // Format data

  return (
    <SbEditable content={story.content} key={story.content._uid}>
      <Article
        title={story.name}
        intro={getRichText(story.content.intro)}
        headerMedia={
          !!story.content.header_media?.length
            ? getMedia(story.content.header_media[0], { maxWidth: 1200 })
            : null
        }
        description={getRichText(story.content.description)}
        details={details}
      >
        {!!blocks.length && (
          <BlockRenderer blocks={blocks} types={blockTypes} />
        )}
        {!!relatedItems.length && (
          <RelatedThumbs heading="More articles" items={relatedItems} />
        )}
      </Article>
    </SbEditable>
  )
}

ArticleStoryblok.defaultProps = {
  articles: [],
}

ArticleStoryblok.propTypes = {
  story: PropTypes.object,
  articles: PropTypes.arrayOf(PropTypes.object),
}

export default ArticleStoryblok
